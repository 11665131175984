:root {
  // Colors
  --color-background: #f3f3f3;
  --color-primary: #3b0a80;
  --color-primary-dark: #222222;
  --color-medium-dark: #000000;
  --color-primary-light: #f5f5f5;
  --color-primary-overlay: #f6faf3;
  --color-on-primary-high-emphasis: #ffffff;
  --color-on-primary-medium-emphasis: #d7e3f1;
  --color-surface-dark: #e7e8ec;
  --color-surface-medium: #ad9fbf;
  --color-surface-light: #ffffff;
  --color-on-surface-disabled: rgba(255, 255, 255, 0.5);
  --color-on-surface-high-emphasis: #7e7e7e;
  --color-on-surface-medium-emphasis: #6b778d;
  --color-on-surface-light-emphasis: rgba(152, 152, 152, 1);
  --color-border-dark: #c8c9cc;
  --color-border-medium: #6f6f6f;
  --color-border-light: rgba(107, 119, 141, 0.3);
  --color-shadow-primary: #0000000d;
  --color-shadow-medium: #00000014;
  --color-error: #ff0000;
  --color-on-error: #ffffff;
  --color-placeholder: #a2a2a2;
}
