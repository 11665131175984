.sidebar-drawer
    .ant-drawer-content-wrapper
        width: auto !important
        .ant-drawer-body
            padding: 0 0
            background-color: #F5F8FA
.sidebar-body
    width: 250px
    padding: 1.5rem
    padding-top: 1rem
    padding-left: 1.38rem
    height: 100%
    display: flex
    flex-direction: column
    background-image: url('/app/assets/bg/sidebar.svg')
    background-repeat: no-repeat
    background-size: cover
    .main-content
        flex-grow: 1
        height: 1%
        overflow: hidden
        .user-info-wrapper
            display: flex
            align-items: center
            .user-img
                width: 35px
                height: 35px
                border-radius: 50%
                object-fit: cover
        .nav-links-wrapper
            .sidebar-items-list
                margin-top: 0.7rem
                padding: 0
                list-style-type: none
                .sidebar-item
                    .sidebar-link
                        display: flex
                        align-items: center
                        color: white
                        text-decoration: none !important
                        border-radius: 20px
                        background-color: transparent
                        padding: 0.4rem 0rem
                        padding-right: 1rem
                        transition: all 250ms
                        border: 2px solid transparent
                        position: relative
                        z-index: 5
                        cursor: pointer
                        &::before
                            content: ''
                            position: absolute
                            display: none
                            left: 0px
                            top: 50%
                            transform: translateY(-50%)
                            background-image: url('/app/assets/bg/sidebar-bill.png')
                            background-size: 100% 100%
                            width: 95%
                            height: 175%
                            z-index: -1
                            pointer-events: none
                        .link-icon
                            width: 30px
                            height: 35px
                            display: flex
                            justify-content: center
                            align-items: center
                            svg
                                max-width: 85% !important
                                max-height: 55% !important
                                path
                                    fill: white !important
                                    stroke: white
                                    transition: all 250ms
                        &.logout-btn
                            color: #C33232
                            cursor: pointer
                            user-select: none
                            .link-icon
                                svg
                                    path
                                        fill: #C33232
                        &.router-link:hover,&.router-link:focus
                            color: white
                            .link-icon
                                svg
                                    path
                                        fill: white
                                        stroke: white
                        &.router-link.active
                            color: white
                            &::before
                                display: block
                            .link-icon
                                svg
                                    path
                                        fill: white !important
                                        stroke: white
@media (min-width: 1500px)
    .sidebar-body
        width: 300px
@media (max-width: 991px)
    .sidebar-drawer
        .ant-drawer-content-wrapper
            .ant-drawer-body
                padding: 0 0
    .sidebar-body
        width: 220px
        padding: 0rem
        padding-top: 0
        background-color: $theme-color
        background-image: unset
@media (max-width: 767px)
    .sidebar-body
        .main-content
            .user-info-wrapper
                .user-img
                    width: 30px
                    height: 30px
