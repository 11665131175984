.img-rounded
    border-radius: 50%
.img-border
    border: 1px solid rgba(black,0.2)
.img-border-theme-color
    border-color: $theme-color
.img-xl
    width: 110px
    height: 110px
    object-fit: cover
.img-lg
    width: 90px
    height: 90px
    object-fit: cover
.img-md
    width: 70px
    height: 70px
    object-fit: cover
.img-Lsm
    width: 60px
    height: 60px
    object-fit: cover

.img-sm
    width: 50px
    height: 50px
    object-fit: cover

.img-xs
    width: 30px
    height: 30px
    object-fit: cover
.img-icon
    width: 23px
    height: 23px
    object-fit: cover
